import pkceChallenge from 'pkce-challenge';
import Config from '../Config';

const AUTH_SET_ACTION = 'AUTH_SET';
const AUTH_CLEAR_ACTION = 'AUTH_CLEAR';
const AUTH_ERROR_ACTION = 'AUTH_ERROR';


// Helper function to retrieve the app client ID from session storage
function getAppClientId() {
  let appClientId = sessionStorage.getItem('appClientId');
  
  return appClientId;
}

export function authDefaultState() {
  return {
    authorizing: false,
    accessToken: null,
    accessTokenJwtString: null,
    idToken: null,
    idTokenJwtString: null,
    state: null,
    error: null,
    showAuthConfirm: false
  };
}

async function validateAuthTokens(idToken, accessToken) {
  try {
    const response = await fetch(`${Config.apiGateway.URL}/validate`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idToken,
        accessToken
      })
    });
    return response;
  } catch (error) {
    throw new Error('Error validating tokens: ' + error.message);
  }
}

// GET /oauth2/authorize
export function authorize(dispatch) {
  dispatch({
    type: AUTH_SET_ACTION,
    payload: {
      authorizing: true
    }
  });

  const state = Date.now();
  const pkce = pkceChallenge(128);

  sessionStorage.setItem('state', state);
  sessionStorage.setItem('codeVerifier', pkce.code_verifier);

  const appClientId = getAppClientId();

  window.location.assign(
    `${Config.forwoodId.URL}/oauth2/authorize?response_type=code&scope=openid&client_id=${appClientId}&redirect_uri=${Config.reactApp.HOSTNAME}&state=${state}&code_challenge=${pkce.code_challenge}`
  );
}

// POST /oauth2/token
async function token(dispatch, payload) {
  try {
    const result = await fetch(
      `${Config.forwoodId.URL}/oauth2/token`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(payload)
      }
    );

    const data = await result.json();

    if (!result.ok) {
      if (result.status === 401) {
        // store the current path so that we can bring the user back once they re-authenticate
        sessionStorage.setItem('lastPath', window.location.pathname);
        // dispatch an action to trigger authentication confirm modal
        dispatch({
          type: AUTH_SET_ACTION,
          payload: {
            showAuthConfirm: true
          }
        });
        return null;
      }

      throw new Error(`Error: ${data.errors}`);
    }

    return data;
  } catch (error) {
    throw new Error('Error during token exchange: ' + error.message);
  }
}

export async function getTokens(dispatch, code) {
  try {
    const appClientId = getAppClientId();

    const payload = await token(dispatch, {
      grant_type: 'authorization_code',
      code,
      client_id: appClientId,
      redirect_uri: Config.reactApp.HOSTNAME,
      code_verifier: sessionStorage.getItem('codeVerifier')
    });

    if (!payload) {
      return null;
    }

    return {
      idToken: payload.id_token,
      accessToken: payload.access_token,
      refreshToken: payload.refresh_token
    };
  } catch (error) {
    throw new Error('Error retrieving tokens: ' + error.message);
  }
}

export async function refreshTokens(dispatch, refreshToken) {
  try {
    const appClientId = getAppClientId();

    const payload = await token(dispatch, {
      grant_type: 'refresh_token',
      client_id: appClientId,
      refresh_token: refreshToken
    });

    if (!payload) {
      return null;
    }

    return {
      idToken: payload.id_token,
      accessToken: payload.access_token,
      refreshToken: payload.refresh_token
    };
  } catch (error) {
    throw new Error('Error refreshing tokens: ' + error.message);
  }
}

function parseJwt(jwtToken) {
  try {
    const base64Url = jwtToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
    return JSON.parse(payload);
  } catch (err) {
    return null;
  }
}

export function hasExpired(jwtToken) {
  const payload = parseJwt(jwtToken);
  if (!payload) {
    return true;
  }

  const isExpired = (Date.now() / 1000) > payload.exp;
  return isExpired;
}

export function clearAuth(dispatch) {
  dispatch({
    type: AUTH_CLEAR_ACTION
  });

  localStorage.removeItem('idToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
}

export function authError(dispatch, payload) {
  clearAuth(dispatch);
  dispatch({
    type: AUTH_ERROR_ACTION,
    payload
  });
}

export async function setAuth(dispatch, payload) {
  const response = await validateAuthTokens(payload.idToken, payload.accessToken);

  if (!response.ok) {
    const data = await response.json();
    return authError(dispatch, {
      status: response.status,
      message: data.error.message
    });
  }

  dispatch({
    type: AUTH_SET_ACTION,
    payload: {
      accessToken: parseJwt(payload.accessToken),
      accessTokenJwtString: payload.accessToken,
      idToken: parseJwt(payload.idToken),
      idTokenJwtString: payload.idToken,
      refreshTokenJwtString: payload.refreshToken
    }
  });

  localStorage.setItem('accessToken', payload.accessToken);
  localStorage.setItem('idToken', payload.idToken);
  localStorage.setItem('refreshToken', payload.refreshToken);

  return true;
}

// Used by child MicroServices of the AdminContainer (eg: company, physicallocation)
// Returns a valid user ID Token with refresh capabilities
export async function getIdToken(dispatch) {
  const idToken = localStorage.getItem('idToken');
  const refreshToken = localStorage.getItem('refreshToken');

  if (!idToken || !refreshToken) {
    return authError(dispatch, {
      status: 401,
      message: 'Invalid session'
    });
  }

  if (!hasExpired(idToken)) {
    return idToken;
  }

  const result = await refreshTokens(dispatch, refreshToken);
  if (!result) {
    return null;
  }

  await setAuth(dispatch, result);
  return result.idToken;
}

// Auth verification method used by the AdminContainer.
// Invoked on app load to determine if an existing session is available,
// if tokens need refreshing or if authorization is required
export async function handleAuthSession(dispatch) {
  const idToken = localStorage.getItem('idToken');
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  // This is a fresh session, begin the authorization flow
  if (!idToken || !refreshToken) {
    return authorize(dispatch);
  }

  // Token has expired. Request a new set using the refresh token
  if (hasExpired(idToken)) {
    const tokens = await refreshTokens(dispatch, refreshToken);
    if (!tokens) {
      throw new Error('Failed to refresh tokens');
    }

    return setAuth(dispatch, tokens);
  }

  await setAuth(dispatch, { idToken, accessToken, refreshToken });
  return true;
}


export function logout(dispatch) {
  clearAuth(dispatch);
  window.location.assign(`${Config.forwoodId.URL}/logout`);
}

export async function exchangeCodeForTokens(dispatch, code, state) {
  if (sessionStorage.getItem('state') !== state) {
    authError(dispatch, {
      status: 401,
      message: 'Invalid auth state'
    });
    return;
  }

  const tokens = await getTokens(dispatch, code);
  if (!tokens) {
    return;
  }

  await setAuth(dispatch, tokens);
  
}

export default (state = authDefaultState(), action) => {
  const { payload } = action;

  switch (action.type) {
    case AUTH_SET_ACTION: {
      return {
        ...state,
        ...payload
      };
    }

    case AUTH_CLEAR_ACTION:
      return {
        ...authDefaultState()
      };

    case AUTH_ERROR_ACTION:
      return {
        ...state,
        error: {
          ...payload
        }
      };

    default:
      return state;
  }
};
